// Color Pallete

$blue: #007bff;
$blue-light: #007bff10;
$blue-hover: #007bff30;
$blue-light-opaque: #eff7ff;
$blue-mid: #007bff99;
$pblue: #4c75f2;
$pblue-light: #4c75f210;
$pblue-hover: #4c75f230;
$pblue-light-opaque: #eff7ff;
$pblue-mid: #4c75f299;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$cherry: #ff073a;
$cherry-mid: #ff073a99;
$cherry-light: #ff073a20;
$cherry-hover: #ff073a30;
$cherry-light-opaque: #ffe0e6;
$red: #dc3545;
$red-light: #dc354520;
$red-mid: #dc354599;
$orange: #fd7e14;
$orange-mid: #fd7e1499;
$orange-light: #fd7e1420;
$orange-hover: #fd7e1430;
$orange-light-opaque: #ffefe2;
$yellow: #ffc107;
$yellow-light: #ffc10720;
$yellow-hover: #ffc10730;
$yellow-mid: #ffc10799;
$yellow-light-opaque: #fff7e0;
$green: #28a745;
$green-light: #28a74520;
$green-hover: #28a74530;
$green-mid: #28a74599;
$teal: #20c997;
$cyan: #17a2b8;
$white: #fff;
$gray: #6c757d;
$gray-light: #6c757d10;
$gray-light-opaque: #f6f6f7;
$gray-hover: #6c757d20;
$gray-mid: #6c757d99;
$gray-dark: #343a40;
$gray-opaque: #f1f1f1;
$primary: #007bff;
$secondary: #6c757d;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #343a40;
$purple: #201aa2dd;
$purple-light: #201aa220;
$purple-hover: #201aa230;
$purple-mid: #201aa299;
$purple-light-opaque: #e3e2f3;
$pink: #fb5581;
$pink-light: #ffa8cb10;
$pink-hover: #ffa8cb30;
$pink-mid: #ffa8cb99;
$brick: #e9830f;
// Dark mode variables
$dark-m-gray: #161625;
$dark-mid-subtext: #bdb8ae;
$dark-m-white: #e1e1e1;
$dark-m-purple: #7ba1ea;
$dark-m-blue: #3391ff;

body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  margin: 0;
  padding: 0;
}

.anchor svg {
  display: none !important;
}
h1 {
  font-family: 'Shan';
  font-size: 24px !important;
  font-weight: 900;
}

h2 {
  font-family: 'Shan';
  font-size: 18px !important;
  font-weight: 400;
}

body {
  font-family: 'Shan', sans-serif;
}
h3 {
  font-family: 'Shan';
  font-size: 14px !important;
  font-weight: 400;
}

h4 {
  font-family: 'Shan';
  font-size: 13px !important;
  font-weight: 900;
}

h5 {
  font-family: 'Shan';
  font-size: 12px !important;
  font-weight: 900;
}

h6 {
  font-family: 'Shan';
  font-size: 11px !important;
  font-weight: 600;
}

.App {
  min-height: 100vh;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  transition: all 0.25s ease-in-out;
  width: 100%;
}

.arrow-up {
  border-bottom: 5px solid $gray-mid;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin-left: 0.25rem;
}

.arrow-down {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid $gray-mid;
  margin-left: 0.25rem;
}

.Navbar {
  display: flex;
  flex-direction: row;
  height: 3.9rem;
  justify-content: space-between;
  padding-bottom: 1rem;
  background-color: #fff;

  & > * {
    align-self: center;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .navbar-left {
    color: $gray;
    cursor: pointer;
    font-family: 'Shan';
    font-size: 12px;
    font-weight: 600;
    padding: 1.5rem;
  }

  .navbar-middle {
    color: $gray;
    font-family: 'Shan';
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;

    span {
      color: $red;
    }
  }

  .navbar-right {
    color: $gray;
    cursor: pointer;
    font-family: 'Shan';
    font-size: 12px;
    font-weight: 600;
    padding: 1.5rem;
    transition: all 0s ease-in-out;
    width: 2rem;

    &:hover {
      background: $pblue;
      color: #fff;
    }
  }

  .expand {
    background: #fff;
    color: $gray;
    display: flex;
    flex-direction: column;
    font-family: 'Shan';
    height: 100vh;
    position: absolute;
    top: 3.85rem;
    transform: none;
    width: 100vw;
    z-index: 9999;

    & > * {
      border-bottom: 1px solid $gray-light;
      border-top: 1px solid $gray-light;
      padding: 1.5rem;
      transition: all 0.15s ease-in-out;

      &:hover {
        background: $gray-hover;
      }

      span {
        &.focused {
          background: $pblue-light;
          color: $pblue;
          padding: 0.25rem;
        }
      }
    }

    .expand-language {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      h5 {
        order: 2;
      }
      .language-switcher {
        order: 1;
        padding-left: 1rem;
      }

      select {
        appearance: none;
        border-radius: 4px;
        font-family: 'Shan';
        font-weight: 900;
        padding: 0.5rem;
        width: auto;
        color: $gray;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }

      &:hover {
        background: none;
      }
    }

    .expand-bottom {
      align-self: flex-start;
      border: 0;

      &:hover {
        background: none;
      }
    }
  }
}

.Home {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 10rem;
  margin-right: 5rem;

  .home-left,
  .home-right {
    display: flex;
    flex-direction: column;
    width: 30rem;
  }

  .home-left {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .home-right {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
}

.alert {
  background: $gray-light;
  border-radius: 5px;
  color: $gray;
  display: flex;
  flex-direction: row;
  font-family: 'Shan';
  font-size: 10px;
  margin-right: 0.5rem;
  padding: 0.5rem;
  width: 6rem;

  svg {
    color: $gray-mid;
    margin-right: 0.25rem;
    margin-top: -0.25rem;
    stroke-width: 2px;
    width: 36px !important;
  }

  .alert-right {
    width: 10rem;

    a {
      background: $gray-light;
      color: $gray;
      padding-left: 0.1rem;
      padding-right: 0.1rem;

      &:hover {
        background: $gray-hover;
      }
    }
  }

  &.is-green {
    background: $green-light;
    color: $green;

    svg {
      stroke: $green-mid;
    }
  }
}

.State {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 10rem;
  margin-right: 5rem;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.5rem;
    width: calc(100% - 3rem);

    & > * {
      align-self: flex-start;
    }

    .header-left {
      h1,
      h5 {
        margin: 0;
      }

      h1 {
        color: $brick;
        max-width: 20rem;
      }

      h5 {
        color: $gray;
        font-weight: 600;
      }
    }

    .header-right {
      color: $purple-mid;
      display: flex;
      flex-direction: column;
      margin-top: 0.1rem;
      text-align: right;

      h2,
      h5 {
        font-weight: 600;
        margin: 0;
      }

      h2 {
        color: $purple;
        font-weight: 900;
        margin-bottom: 1rem;
      }

      a {
        background: $purple-light;
        color: inherit;
        text-decoration: none;
        transition: all 0.2s ease-in-out;

        &:hover {
          background: $purple-hover;
        }
      }
    }
  }

  .state-left,
  .state-right {
    display: flex;
    flex-direction: column;
    width: 30rem;
  }

  .state-left {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .state-right {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  a {
    text-decoration: none;
  }

  .to-essentials {
    align-self: center;
    background: $gray-light;
    border-radius: 5px;
    display: none;
    flex-direction: row;
    justify-content: center;
    padding: 0.5rem;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: $gray-hover;
    }

    h2 {
      color: $gray-mid;
      font-weight: 600;
    }

    svg {
      color: $gray-mid;
      margin-bottom: 2px;
      margin-left: 1rem;
    }

    & > * {
      align-self: center;
    }
  }

  .meta-secondary {
    align-self: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }

  .district-bar,
  .to-essentials {
    width: 100%;
  }

  .district-bar-right {
    display: flex;
    justify-content: center;
    position: relative;

    .happy-sign {
      display: flex;
      flex-direction: row;
      position: absolute;
      top: 4rem;
    }
  }

  .MapExplorer,
  .meta-secondary {
    width: calc(100% - 3rem);
  }

  .Minigraph {
    .svg-parent {
      width: calc(25%) !important;
    }
  }

  .MapExplorer {
    margin-top: 2rem;

    .header,
    .map-stats,
    .back-button,
    .last-update,
    .unknown,
    .tabs-map,
    .footnote,
    .state-page-button {
      display: none;
    }

    .anchor {
      display: none;
    }

    .svg-parent {
      &:not(.legend) {
        height: 400px;
        width: 100%;

        svg {
          height: 100%;
        }
      }
    }
  }
}

.breadcrumb {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: 'Shan';
  font-size: 0.75rem;
  padding-left: 1.5rem;

  ul {
    background: $light;
    border: 2px solid $gray-light;
    box-shadow: none;
    max-height: 20rem;
    overflow-y: scroll;
  }

  .item {
    a {
      color: $gray;
      width: 100%;
    }

    &:hover {
      background: $gray-hover;

      a {
        background: transparent;
        color: $gray;
      }
    }
  }

  a {
    color: $gray;
    font-family: 'Shan';
    font-size: 0.75rem;
    margin-right: 0.25rem;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:not(:first-child) {
      margin-left: 0.25rem;
    }

    &:hover {
      background: $gray-hover;
      text-decoration: none;
    }

    &.selected {
      background: $gray-light;
      color: $gray;
    }
  }

  .caret {
    color: $gray;
    margin-left: 0;
    transition: all 0.1s ease-in-out;

    &:hover {
      color: $gray-dark;
    }
  }

  summary {
    &:focus {
      outline: none;
    }
  }
}

.height-22 {
  height: 22px;
}

.disclaimercontainer {
  svg {
    margin-left: 2px;
  }

  .mobile-disclaimer-button {
    background-color: #fff;
    color: #808080;
    font-size: xx-small;
    justify-content: center;

    svg {
      font-size: 0.75rem;
    }
  }

  .disclaimer-button {
    background: none;
    color: $gray-mid;
    font-size: 0.7rem;
    font-weight: 500;
    text-transform: none;
  }
}

.Resources {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 9rem;
  margin-right: 4rem;

  .resource-left,
  .resource-right {
    display: flex;
    flex-direction: column;
    width: 30rem;
  }

  .resource-left {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .resource-right {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .tableandaccordions {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  table {
    align-self: center;
    border-collapse: separate;
    border-spacing: 3px 2px;
    font-family: 'Shan';
    position: relative;
    table-layout: fixed;
    text-transform: none;
    width: 100%;

    thead {
      background: $purple-light;
      color: $gray-dark;
      font-size: 0.9rem;
      text-align: center;

      th {
        border-radius: 5px;
        cursor: pointer;
        padding: 0.5rem;
        transition: all 0.1s ease-in-out;
        z-index: 99;

        &.descriptionCol {
          width: 50%;
        }

        &.sticky {
          background: $gray-opaque;
          top: 0;
        }

        &:hover {
          background: #ecedee;
        }

        .heading-content {
          align-items: center;
          display: flex;
          flex-direction: row;
          height: 0.9rem;
          justify-content: space-between;
          position: relative;

          abbr {
            text-align: right;
          }

          svg {
            color: $gray-mid;
            margin: 0;
            margin-left: 0.25rem;
            margin-top: 0.15rem;
            right: 0;
            stroke-width: 4px;
            width: 10px;
          }
        }
      }
    }

    tbody {
      color: $gray;
      max-width: 10rem;

      tr {
        transition: background 0.1s ease-in-out;

        &:hover {
          background: $gray-hover !important;
        }

        &.divider {
          background: #6c757d10 !important;
          height: 0.5rem;
        }

        &.spacer {
          background: #fff !important;
          height: 0.5rem;
        }
      }

      td {
        border-radius: 5px;
        font-size: 0.7rem;
        text-align: left;

        &:first-child {
          max-width: 7.5rem;
          text-align: left;
          word-wrap: break-word;
        }

        .deltas {
          font-size: 11px !important;
          margin-right: 0.25rem;
        }
      }

      .state-last-update {
        background: transparent;
        transform: translateX(1rem);

        &:hover {
          background: #fff !important;
        }

        td {
          .last-update {
            align-items: baseline;
            display: flex;
            flex-direction: row;
            text-align: left;
            width: 100%;
          }
        }
      }

      .district-heading {
        background: $gray-light;
        transform: translateX(1rem);

        td {
          background: $gray-light;
          color: $gray-dark;
          font-size: 0.75rem;
          font-weight: 900;
          padding: 0.45rem;

          .heading-content {
            align-items: center;
            display: flex;
            flex-direction: row;
            height: 0.9rem;
            justify-content: space-between;
            position: relative;

            abbr {
              text-align: right;
            }

            svg {
              color: $gray-mid;
              margin: 0;
              margin-left: 0.25rem;
              margin-top: 0.15rem;
              right: 0;
              stroke-width: 4px;
              width: 10px;
            }
          }
        }
      }

      .district {
        transform: translateX(1rem);
      }
    }

    .affected-count {
      color: $gray-mid;
      margin: 0;
      position: absolute;
      right: 0;
      top: -1rem;
    }

    .is-total {
      td {
        background: $gray-hover;
      }
    }

    .dropdown {
      background: $gray-light;
      border-radius: 50%;
      cursor: pointer;
      height: 13px;
      left: -0.75rem;
      margin-top: 0.5rem;
      position: absolute;
      transition: background 0.2s ease-in-out;
      width: 13px;

      &:hover {
        background: $gray-hover;
      }

      svg {
        left: 1px;
        position: absolute;
        stroke-width: 3;
        top: -0.35rem;
        width: 11px;
      }
    }
  }
}

.filtersection {
  animation-duration: 1s;
  animation-name: fadeInUp;
  animation-timing-function: ease-in;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 80%;

  .filtertitle {
    align-self: center;
    color: $cherry;
  }
}

.resourcefilters {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .button {
    padding: 0.4rem;
  }
}

.resourcefilter {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 1rem;
  margin-right: 1rem;

  .filterlabel {
    color: $indigo;
    font-family: 'Shan';
    font-size: 11px !important;
    font-weight: 600;
    text-transform: uppercase;
  }

  select {
    width: 100%;
  }
}

.searchbar {
  font-family: 'Shan';
  font-size: 0.7rem !important;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
  width: 100%;

  #input-field-searchbar {
    font-family: 'Shan';
    font-size: 0.7rem !important;
    font-weight: 400;
    height: 0.1rem;
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .button {
    align-items: center;
    height: 70%;
    justify-content: space-between;
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-top: 0.7rem;
    padding-left: 1.1rem;
    width: 3rem;
  }

  select {
    margin: 0.7rem 0.7rem 0.2rem 0.1rem;
  }

  input {
    margin: 0.7rem 0.7rem 0.2rem;
    width: 100px;
  }
}

.paginationbutton {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Banner {
  background: $purple-light;
  color: $purple;
  display: flex;
  flex-direction: row;
  font-family: 'Shan';
  font-size: 0.8rem;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;

  .snippet {
    align-self: center;
    cursor: pointer;
    padding: 1.5rem;
    text-align: center;
    width: calc(100% - 10rem);
    word-wrap: break-word;
  }
}

.Search {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  position: relative;
  width: 100%;

  & > * {
    align-self: center;
  }

  label {
    color: $gray;
    font-family: 'Shan';
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }

  .line {
    background: $gray-light;
    height: 1rem;
    width: 2px;
  }

  input {
    -moz-box-shadow: 0 10px 35px rgba(0, 0, 0, 0.1);
    -webkit-appearance: none !important;
    -webkit-box-shadow: 0 10px 35px rgba(0, 0, 0, 0.1);
    background: #fff;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 10px 35px rgba(0, 0, 0, 0.1);
    color: $gray;
    font-family: 'Shan';
    font-size: 1rem;
    height: 2rem;
    outline: none;
    padding: 1rem;
    padding-left: 3rem;
    transition: all 0.2s ease-in-out;
    width: calc(100% - 4rem);
  }

  .search-button {
    svg {
      color: $gray-mid;
      left: 0.75rem;
      position: absolute;
      top: 4.15rem;
      transition: all 0.2s ease-in-out;
    }

    &.is-expand {
      svg {
        left: 0.5rem;
      }
    }
  }

  .close-button {
    background: $gray-light !important;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 20px;
    justify-content: center;
    position: absolute;
    right: 1rem;
    top: 4.15rem;
    transition: all 0.2s ease-in-out;
    width: 20px;

    &:hover {
      background: $gray-hover !important;
    }

    &.is-expand {
      right: 0.75rem;
    }

    svg {
      align-self: center;
      position: absolute;
      stroke: $gray-mid;
      stroke-width: 3;
      width: 12px;
    }
  }

  .expanded {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    padding: 1rem;
    padding-bottom: 0;
    width: calc(100% - 4rem);

    & > * {
      flex: 1;
    }

    h4,
    h3 {
      color: $gray;
      font-weight: 600;
      margin: 0;
      margin-bottom: 0.5rem;
    }

    h3 {
      color: $pblue;
      margin-bottom: 0.75rem;
    }
  }

  .results {
    padding: 1rem;
    padding-bottom: 0;
    width: calc(100% - 2rem);

    a {
      text-decoration: none !important;
    }

    .result,
    .essential-result {
      align-self: flex-start;
      border-bottom: 2px solid $gray-light;
      color: $gray;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      font-family: 'Shan';
      font-size: 0.9rem;
      justify-content: space-between;
      padding: 1rem;
      transition: all 0.2s ease-in-out;

      &:hover {
        background: $gray-light;
      }

      & > * {
        align-self: center;
      }

      .result-name {
        max-width: 15rem;
      }

      .result-type {
        background: $yellow-light;
        border-radius: 2px;
        color: $orange;
        font-size: 0.75rem;
        padding: 0.25rem;
      }
    }

    .essential-result {
      display: flex;
      flex-direction: column;

      .result-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .result-top-left {
          display: flex;
          flex-direction: column;

          .result-location {
            display: flex;
            flex-direction: row;
            font-size: 0.75rem;
          }
        }
      }

      .result-category {
        align-self: flex-start;
        background: $pink-light;
        border-radius: 2px;
        color: $pink;
        display: flex;
        flex-direction: row;
        font-size: 0.75rem;
        height: 1rem;
        padding: 0.25rem;
        text-align: right;

        & > * {
          align-self: center;
        }

        svg {
          margin-left: 0.25rem;
          stroke-width: 3px;
          width: 12px;
        }
      }

      .result-description {
        align-self: flex-start;
        font-size: 0.75rem;
        margin-top: 1rem;
      }

      .result-contact {
        align-self: flex-start;
        background: $gray-light;
        border-radius: 2px;
        display: flex;
        flex-direction: row;
        font-size: 0.75rem;
        height: 1rem;
        margin-top: 0.5rem;
        padding: 0.25rem;
        text-align: right;

        & > * {
          align-self: center;
        }

        svg {
          margin-right: 0.25rem;
          stroke: $gray-mid;
          stroke-width: 3px;
          width: 12px;
        }
      }
    }
  }

  .suggestions {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;

    .suggestion {
      color: $gray-mid;
      display: flex;
      flex-direction: row;
      font-family: 'Shan';

      div {
        padding-top: 1px;
      }

      h4 {
        cursor: pointer;
        margin-left: 0.25rem;
        word-wrap: wrap;

        &:hover {
          text-decoration: underline;
          text-decoration-thickness: 2px;
        }
      }
    }
  }
}

.header {
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    align-self: center;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 1rem;

    & > * {
      align-self: center;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    h5 {
      color: $gray;
      font-weight: 600;
      margin: 0;
    }

    svg {
      color: $gray-mid;
      cursor: pointer;
      stroke-width: 3;
      width: 16px;

      &:hover {
        stroke: $gray;
      }
    }

    .bell-icon {
      height: 24px;
      position: relative;

      .indicator {
        background: $pblue;
        border-radius: 50%;
        height: 7px;
        position: absolute;
        right: -3px;
        top: -3px;
        width: 7px;
      }
    }
  }
}

.last-update {
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  margin-top: 0.25rem;
  text-align: right;

  h6 {
    color: $green-mid;
    font-weight: 900;
  }

  h3 {
    color: $green;
    font-weight: 600;
  }

  h3,
  h6 {
    margin: 0;
  }
}

.button {
  background: $blue-light;
  border: 0;
  border-radius: 5px;
  color: $blue-mid;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: 'Shan';
  font-size: 0.75rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;
  outline: none;
  padding: 0.5rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: background 0.2s ease-in-out;

  svg {
    padding-right: 6px;
    stroke-width: 2px;
    width: 20px;
  }

  &.is-purple {
    background: $purple-light;
    color: $purple-mid;

    &:hover {
      background: $purple-hover;
    }
  }

  &.is-green {
    background: $green-light;
    color: $green-mid;

    &:hover {
      background: $green-hover;
    }
  }

  &:hover {
    background: $blue-hover;
  }

  & > * {
    align-self: center;
  }
}

.telegram {
  background: $gray-light !important;
  color: #08c !important;
  justify-content: center;
  padding-left: 0.65rem;
  width: 15.5rem;

  &:hover {
    background: $gray-hover !important;
  }
}

.github {
  background: #000 !important;
  color: #fff !important;
  justify-content: center;
  padding-left: 0.65rem;
  transition: all 0.2s ease-in-out;
  width: 15.5rem;

  &:hover {
    background: $gray-dark !important;
  }
}

.twitter {
  width: 15.5rem;
}

.excel {
  background: #33a66730;
  color: #33a667;

  &:hover {
    background: #33a66750;
  }
}

.map-switcher {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  top: 13rem;
  width: 30rem;

  .highlight {
    background: $cherry-light;
    position: absolute;
    width: calc(25%) !important;
    z-index: -1;

    &.active {
      background: $blue-light;
    }

    &.recovered {
      background: $green-light;
    }

    &.deceased {
      background: $gray-light;
    }
  }

  .highlight,
  .clickable {
    border-radius: 5px;
    cursor: pointer;
    height: 12rem;
    width: calc(25%);
    z-index: 10;
  }
}

.Level {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30rem;

  .level-item {
    display: flex;
    flex-direction: column;
    width: calc(25%);

    & > * {
      align-self: center;
    }
  }

  h1,
  h5 {
    margin-bottom: 0;
  }

  h1 {
    font-weight: 600;
  }

  h4 {
    margin-bottom: -0.5rem;
  }

  h5 {
    font-weight: 600;
  }

  .is-cherry {
    h1,
    h5 {
      color: $cherry;
    }

    h4 {
      color: $cherry-mid;
    }
  }

  .is-blue {
    h1,
    h5 {
      color: $blue;
    }

    h4 {
      color: $blue-mid;
    }
  }

  .is-green {
    h1,
    h5 {
      color: $green;
    }

    h4 {
      color: $green-mid;
    }
  }

  .is-gray {
    h1,
    h5 {
      color: $gray;
    }

    h4 {
      color: $gray-mid;
    }
  }
}

abbr {
  text-decoration: none;

  &.is-cherry {
    color: $cherry;
  }

  &.is-blue {
    color: $blue;
  }

  &.is-green {
    color: $green;
  }

  &.is-gray {
    color: $gray;
  }
}

.table-fineprint {
  color: $gray-mid;
  font-weight: 600;
  margin: 0;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
  text-align: right;

  a {
    border-bottom: 2px solid $gray-hover;
    color: inherit;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: $gray-hover;
    }
  }
}

.sticky {
  position: sticky;
}

.Table {
  margin-bottom: 1rem;
}

table {
  align-self: center;
  border-collapse: separate;
  border-spacing: 3px 4px;
  font-family: 'Shan';
  position: relative;
  width: 30rem;

  thead {
    background: $gray-light;
    color: $gray;
    font-size: 0.75rem;
    text-align: left;

    th {
      border-radius: 5px;
      cursor: pointer;
      padding: 0.5rem;
      transition: all 0.1s ease-in-out;
      z-index: 99;

      &.sticky {
        background: $gray-opaque;
        top: 4px;
      }

      &:hover {
        background: #ecedee;
      }

      .heading-content {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 0.9rem;
        justify-content: space-between;
        position: relative;

        abbr {
          text-align: right;
        }

        svg {
          color: $gray-mid;
          margin: 0;
          margin-left: 0.25rem;
          margin-top: 0.15rem;
          right: 0;
          stroke-width: 4px;
          width: 10px;
        }
      }
    }
  }

  tbody {
    color: $gray;
    max-width: 10rem;

    tr {
      cursor: pointer;

      &.is-highlighted {
        background: $gray-hover !important;
      }

      &:hover {
        background: $gray-hover !important;

        .dropdown {
          background: $gray-hover;
        }
      }

      &.divider {
        background: #6c757d10 !important;
        height: 0.5rem;
      }

      &.spacer {
        background: #fff !important;
        height: 0.5rem;
      }
    }

    td {
      border-radius: 5px;
      font-size: 0.9rem;
      padding: 0.25rem;
      text-align: right;

      &:first-child {
        max-width: 7.5rem;
        text-align: left;
        word-wrap: break-word;
      }

      .deltas {
        display: inline-block;
        font-size: 11px !important;

        svg {
          height: 9px;
          stroke-width: 3;
          vertical-align: -0.25px;
          width: 9px;
        }
      }

      a {
        height: 1px;

        svg {
          align-self: center;
          color: $gray-mid;
          margin-left: 2px;
          stroke-width: 3px;
          transition: all 0.2s ease-in-out;
          vertical-align: -0.435rem;
          width: 12px;

          &:hover {
            stroke: $gray;
          }
        }
      }
    }

    .table__count-text {
      margin-left: 0.25rem;
    }

    .state-last-update {
      background: transparent;
      color: $green !important;
      transform: translateX(1rem);

      &:hover {
        background: #fff !important;
      }

      td {
        .last-update {
          align-items: baseline;
          display: flex;
          flex-direction: row;
          text-align: left;
          width: 100%;

          h6 {
            color: $green !important;
            font-weight: 600;
          }
        }
      }
    }

    .district-heading {
      background: $gray-light;
      transform: translateX(1rem);

      &:hover {
        background: $gray-light !important;
      }

      td {
        background: $gray-light;
        color: $gray-dark;
        font-size: 0.75rem;
        font-weight: 900;
        padding: 0.45rem;

        .heading-content {
          align-items: center;
          display: flex;
          flex-direction: row;
          height: 0.9rem;
          justify-content: space-between;
          position: relative;

          abbr {
            text-align: right;
          }

          svg {
            color: $gray-mid;
            margin: 0;
            margin-left: 0.25rem;
            margin-top: 0.15rem;
            right: 0;
            stroke-width: 4px;
            width: 10px;
          }
        }

        &.state-page-link {
          background: $white;
          padding: 0;
          text-align: left;

          div {
            align-items: center;
            background: $yellow-light;
            border-radius: 5px;
            color: $orange;
            float: left;
            font-size: 0.75rem;
            padding: 0.45rem;
            transition: all 0.2s ease-in-out;

            &:hover {
              background: $yellow-hover;

              svg {
                stroke: $orange;
              }
            }

            svg {
              color: $orange-mid;
              height: 12px;
              margin-bottom: 1px;
              margin-left: 0.25rem;
              stroke-width: 3px;
              transition: all 0.2s ease-in-out;
              vertical-align: middle;
              width: 12px;
            }
          }
        }
      }
    }

    .district {
      transform: translateX(1rem);
    }
  }

  .affected-count {
    color: $gray-mid;
    margin: 0;
    position: absolute;
    right: 0;
    top: -1rem;
  }

  .is-total {
    td {
      background: $gray-hover;
    }
  }

  .table__title-wrapper {
    position: relative;

    & > * {
      align-self: center;
    }

    .actual__title-wrapper {
      .__react_component_tooltip {
        font-size: 0.8em;
        max-width: 200px;
      }

      svg {
        color: $gray-mid;
        height: 12px;
        padding-left: 2px;
        stroke-width: 3;
        vertical-align: -1.5px;
        width: 12px;
      }
    }
  }

  .unknown {
    .__react_component_tooltip {
      font-size: 0.8em;
      max-width: 200px;
    }

    svg {
      color: $gray-mid;
      height: 12px;
      padding-left: 2px;
      stroke-width: 3;
      vertical-align: -1.5px;
      width: 12px;
    }
  }

  .dropdown {
    background: $gray-light;
    border-radius: 50%;
    cursor: pointer;
    height: 13px;
    left: -1.25rem;
    position: absolute;
    top: 0.125rem;
    transition: background 0.2s ease-in-out;
    width: 13px;

    &:hover {
      background: $gray-hover;
    }

    svg {
      left: 1px;
      position: absolute;
      stroke-width: 3;
      top: -0.35rem;
      width: 11px;
    }
  }
}

#chart {
  z-index: 10;
}

.back-button {
  z-index: 11;
}

.anchor {
  cursor: pointer;
  position: absolute;
  right: 0;
  transform: rotate(45deg);
  transition: all 0.1s ease-in-out;

  svg {
    stroke: $gray-mid;
    stroke-width: 3;
    width: 16px;

    &:hover {
      stroke: $gray;
    }
  }

  &.stickied {
    transform: rotate(0deg);

    svg {
      stroke: $gray;
    }
  }
}

.MapExplorer {
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 30rem;

  &.stickied {
    position: sticky !important;
    top: 0;
  }

  .header {
    align-self: flex-start;
    color: $gray;
    display: flex;
    flex-direction: column;

    h1,
    h6 {
      margin: 0;
    }

    h6 {
      margin-bottom: 2rem;
      margin-top: 0.75rem;
    }
  }

  .svg-parent {
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-top: 2rem;
    overflow: hidden;
    width: 90%;

    svg {
      align-self: center;
      width: 100%;

      text {
        fill: $gray;
        font-family: 'Shan';
        font-size: 10px;
        font-weight: 600;
        text-align: right;
      }
    }
  }

  .legend {
    margin-top: 1em;
    width: 100%;
  }

  .map-hover {
    stroke: #ff073a;
    stroke-width: 2;

    &.active {
      stroke: #007bff;
    }

    &.recovered {
      stroke: #28a745;
    }

    &.deceased {
      stroke: #6c757d;
    }
  }

  .back-button {
    background: $yellow-light;
    color: $orange;
    position: absolute;
    right: 0;
    top: 2.75rem;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: $yellow-hover;
    }
  }

  .state-page-button {
    background: $yellow-light;
    border-radius: 5px;
    color: $orange;
    font-size: 0.75rem;
    height: 1.5rem;
    padding: 0.45rem;
    padding-left: 0.75rem;
    position: absolute;
    right: 0;
    top: -0.5rem;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: $yellow-hover;
    }

    abbr {
      text-transform: none;
    }

    svg {
      height: 12px;
      margin-left: 0.25rem;
      stroke: $orange-mid;
      stroke-width: 3px;
      vertical-align: middle;
      width: 12px;
    }
  }

  .meta {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 4rem;
    justify-content: space-between;

    h2 {
      color: #e9830f;
      font-weight: 900;
      margin-top: 0;
      width: calc(100% - 10rem);
      word-wrap: break-word;

      &.active {
        color: $blue;
      }

      &.recovered {
        color: $green;
      }

      &.deceased {
        color: $gray;
      }
    }

    h4 {
      color: $gray-mid;
      margin: 0;
      word-wrap: break-word;
    }

    a {
      width: 10rem;
    }

    .district {
      color: #e9830f;
      line-height: 15px;
      margin: 0;

      span {
        font-size: 0.75rem;
        font-weight: 600;
      }

      &.active {
        color: $blue;
      }

      &.recovered {
        color: $green;
      }

      &.deceased {
        color: $gray;
      }
    }

    .unknown {
      font-weight: 600;
      position: absolute;
      right: 0;
      text-align: right;
      top: 5.5rem;
      width: 5rem;
    }
  }

  .map-stats {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    position: relative;
    width: 100%;
    z-index: 10;

    h1,
    h5,
    h6,
    h3 {
      margin: 0;
    }

    h3 {
      color: $gray-mid;
      font-weight: 900;
    }

    .stats {
      background: $cherry-light;
      border-radius: 5px;
      color: $cherry-mid;
      cursor: pointer;
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 3rem;
      margin-bottom: 1rem;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      padding: 0.25rem;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      position: relative;
      transition: all 0.1s ease-in-out;

      .stats-bottom {
        display: flex;
        flex-direction: column;

        h6 {
          font-weight: 900;
          margin-bottom: 0.25rem;
          margin-top: auto;
        }
      }

      h1 {
        color: $cherry;
        font-size: 1em !important;
        font-weight: 600;
      }

      &.is-green {
        background: $green-light;

        h5,
        h6 {
          color: $green-mid;
        }

        h1 {
          color: $green;
        }
      }

      &.is-blue {
        background: $blue-light;

        h5,
        h6 {
          color: $blue-mid;
        }

        h1 {
          color: $blue;
        }
      }

      &.is-gray {
        background: $gray-light;

        h5,
        h6 {
          color: $gray-mid;
        }

        h1 {
          color: $gray;
        }
      }

      &.is-yellow {
        background: $warning;

        h5,
        h6 {
          color: $white;
        }

        h1 {
          color: $white;
        }
      }

      &.is-purple {
        background: $purple-light;
        padding-right: 1rem;

        h5,
        h6 {
          color: $purple-mid;

          &.timestamp {
            font-weight: 900;
            margin: 0;
          }
        }

        h1 {
          color: $purple;
        }

        svg {
          cursor: pointer;
          position: absolute;
          right: 0.35rem;
          stroke: $purple-mid;
          stroke-width: 3;
          top: 0;
          transition: stroke 0.2s ease-in-out;
          width: 12px;

          &:hover {
            stroke: $purple;
          }
        }

        .tooltip-tested {
          svg {
            bottom: 0.3rem;
            top: auto;
          }
        }
      }

      &.focused {
        margin-bottom: 0.5rem;
        margin-top: -0.5rem;
        padding-bottom: 1.25rem;
        padding-top: 1.25rem;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  #chart,
  #legend {
    filter: saturate(1.5);
  }

  .disclaimer {
    backdrop-filter: saturate(180%) blur(20px);
    background: $gray-light;
    border-radius: 5px;
    bottom: 50%;
    color: $gray-dark;
    display: flex;
    flex-direction: row;
    font-family: 'Shan';
    font-size: 12px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5rem;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 14rem;
    z-index: 99;

    svg {
      margin-right: 0.25rem;
      margin-top: -0.9rem;
      stroke-width: 2px;
      width: 20px !important;
    }
  }

  .footnote {
    &.table-fineprint {
      margin-bottom: 0;
      margin-top: 1em;
      text-align: left;
    }
  }
}

.tooltip-tested {
  pointer-events: all;

  svg {
    cursor: pointer;
    height: 0.95em;
    stroke-width: 3;
    width: 1em;
  }

  .__react_component_tooltip {
    font-family: 'Shan';
    font-size: 11px;
    font-weight: 600;
    padding: 0.25rem;
    text-align: center;
    width: 200px;
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 99;

  .tab {
    background: $gray-light;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: $gray-mid;
    cursor: pointer;
    height: 3.25rem;
    margin-right: 0.25rem;
    min-width: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: $gray-hover;
    }

    &.focused {
      background: $gray-opaque;
      color: $gray;
    }
  }
}

.tabs-map {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 99;

  h4 {
    display: table-cell;
    font-family: 'Shan';
    font-size: 12px !important;
    font-weight: 900;
    height: 2rem;
    margin-bottom: 0;
    margin-top: 0;
    vertical-align: middle;
  }

  .tab {
    background: $gray-light;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: $gray-mid;
    cursor: pointer;
    height: 2rem;
    margin-right: 0.25rem;
    min-width: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: $gray-hover;
    }

    &.focused {
      background: $gray-opaque;
      color: $gray;
    }

    &.disabled {
      background: $gray-opaque;
      color: $gray-mid;
    }
  }

  sup {
    position: relative;
    top: -0.5em;
    vertical-align: top;
  }
}

.TimeSeriesExplorer {
  align-self: center;
  width: 30rem;

  &.stickied {
    position: sticky !important;
    top: -6.5rem;
  }

  .alert {
    margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: 0;
    margin-top: 0.5rem;
    padding: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: 16rem;

    & > * {
      align-self: center;
    }

    svg {
      margin: 0 !important;
      margin-right: 0.25rem !important;
      width: 14px !important;
    }

    .alert-right {
      width: 100%;
    }
  }
}

.TimeSeries {
  align-self: center;
  width: 100%;

  .svg-parent {
    height: 10rem;
    margin-bottom: 1rem;
  }
}

.timeseries-header {
  align-self: center;
  margin-top: 3rem;
  width: 100%;

  h1 {
    color: $gray;
    margin: 0;
    margin-bottom: 2rem;
    text-align: center;
  }

  .scale-modes {
    align-items: flex-end;
    bottom: 0;
    color: $gray;
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    right: 0;
    z-index: 99;

    label {
      color: $gray-mid;
      font-family: 'Shan';
      font-size: 0.75rem;
      font-weight: 900;
      margin-right: 0.25rem;
      transition: all 0.2s ease-in-out;
      z-index: 99;
    }

    &:hover {
      label {
        &.main {
          color: $gray;
        }
      }
    }

    & > * {
      align-self: center;
    }
  }

  .timeseries-mode,
  .timeseries-logmode {
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
  }
}

.disabled {
  input {
    &.switch {
      background: $gray-light !important;
      border: $gray-light 2px solid !important;
      cursor: not-allowed !important;

      &::after {
        background: $gray-light !important;
      }
    }
  }
}

input {
  &.switch {
    appearance: none;
    background-color: #fff;
    border: 2px solid #d9dadc;
    border-radius: 10px;
    cursor: pointer;
    height: 14px;
    margin: 0;
    outline: none;
    position: relative;
    transition: all 300ms ease-in-out;
    width: 24px;

    &::after {
      background-color: $gray-mid;
      border-radius: 50%;
      content: '';
      height: 10px;
      left: 0;
      position: absolute;
      top: 0;
      transition: all 300ms ease-in-out;
      width: 10px;
    }

    &:checked {
      background-color: $gray-mid;
      transition: all 300ms ease-in-out;

      &::after {
        background-color: $light;
        left: 10px;
        transition: all 300ms ease-in-out;
      }
    }
  }
}

.trends-state-name {
  display: flex;
  flex-direction: row;
  text-align: center;

  select {
    appearance: none;
    background-color: #eee;

    background-image: linear-gradient(45deg, transparent 50%, #808080 50%),
      linear-gradient(135deg, #808080 50%, transparent 50%);
    background-position: calc(100% - 13px) 50%, calc(100% - 8px) 50%;
    background-repeat: no-repeat;
    background-size: 5px 5px, 5px 5px;
    border: 2px solid #e8e8e9;
    border-radius: 4px;
    color: $gray;
    cursor: pointer;
    font-family: 'Shan';
    font-weight: 900;
    margin-left: -1px;
    margin-top: 1rem;
    padding: 0.5rem;
    width: auto;

    &:focus {
      outline: none;
    }
  }
}

.TimeSeries,
.Minigraph {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;

  .stats {
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    left: 0.5rem;
    padding: 0.25rem;
    pointer-events: none;
    position: absolute;
    top: 0.5rem;

    h2,
    h5,
    h6 {
      color: $cherry-mid;
      margin: 0;
      transition: all 0.15s ease-in-out;
    }

    h5 {
      &.title {
        color: $cherry;
      }
    }

    h2,
    h6 {
      color: $cherry;
    }

    .stats-bottom {
      display: flex;
      flex-direction: row;

      h6 {
        margin-bottom: 0.1rem;
        margin-left: 0.25rem;
      }

      & > * {
        margin-top: auto;
      }
    }

    &.is-green {
      h5 {
        color: $green-mid;

        &.title {
          color: $green;
        }
      }

      h2,
      h6 {
        color: $green;
      }
    }

    &.is-gray {
      h5 {
        color: $gray-mid;

        &.title {
          color: $gray;
        }
      }

      h2,
      h6 {
        color: $gray;
      }
    }

    &.is-blue {
      h5 {
        color: $blue-mid;

        &.title {
          color: $blue;
        }
      }

      h2,
      h6 {
        color: $blue;
      }
    }

    &.is-purple {
      h5 {
        color: $purple-mid;

        &.title {
          color: $purple;
        }
      }

      h2,
      h6 {
        color: $purple;
      }

      .tooltip-tested {
        svg {
          width: 1em;
        }
      }
    }
  }

  .svg-parent {
    align-self: center;
    background: $cherry-light;
    border-radius: 5px;
    display: flex;
    position: relative;
    width: 30rem;

    svg {
      width: 100%;

      .domain,
      .tick,
      line {
        stroke: $cherry;
        stroke-width: 1.5;
      }

      text {
        color: $cherry-mid;
        font-family: 'Shan';
        font-size: 9px;
        font-weight: 600;
        stroke: transparent;
      }
    }

    &.is-green {
      background: $green-light;

      svg {
        .domain,
        .tick,
        line {
          stroke: $green;
        }

        text {
          color: $green-mid;
          stroke: transparent;
        }
      }
    }

    &.is-gray {
      background: $gray-light;

      svg {
        .domain,
        .tick,
        line {
          stroke: $gray;
        }

        text {
          color: $gray-mid;
          stroke: transparent;
        }
      }
    }

    &.is-blue {
      background: $blue-light;

      svg {
        .domain,
        .tick,
        line {
          stroke: $blue;
        }

        text {
          color: $blue-mid;
          stroke: transparent;
        }
      }
    }

    &.is-purple {
      background: $purple-light;

      svg {
        .domain,
        .tick,
        line {
          stroke: $purple;
        }

        text {
          color: $purple-mid;
          stroke: transparent;
        }
      }
    }
  }
}

.Minigraph {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 30rem;

  .svg-parent {
    background: transparent !important;
    width: calc(25%);
  }

  .tooltip {
    position: fixed;
    right: 0;
    top: 0;
  }
}

.floating-buttons {
  bottom: 1rem;
  display: flex;
  flex-direction: column;
  padding: 0;
  position: fixed;
  right: 1rem;
  width: 3rem;
  z-index: 1;

  button {
    backdrop-filter: saturate(180%) blur(2px);
    background: $yellow-mid;
    border: 0;
    border-radius: 50%;
    color: $orange;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin: 0.25rem;
    outline: none;
    padding: 0.75rem;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #ffa500;

      svg {
        stroke: #ffa500;
      }
    }

    svg {
      align-self: center;
      stroke: $orange;
    }
  }
}

.Links {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.link {
  align-self: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 30rem;

  a {
    background: $blue-light;
    color: $blue;
    font-family: 'Shan';
    font-weight: 900;
    text-decoration: none;
    text-transform: uppercase;
    transition: background 0.2s ease-in-out;
    word-wrap: break-word;

    &:hover {
      background: $blue-hover;
    }
  }

  h3 {
    color: $gray-dark;
    font-weight: 900;
    margin-bottom: 0;
  }
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5rem;
  margin-top: 5rem;

  & > * {
    align-self: center;
    text-align: center;
  }

  h5 {
    color: $gray;
    margin: 0;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  img {
    height: 2rem;
    width: 2rem;
  }
}

.Summary {
  display: flex;
  flex-direction: column;
  height: 600px;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  width: 600px;

  img {
    height: 2rem;
    width: 2rem;
  }

  .link {
    margin: 0;
  }

  .header {
    h1 {
      margin-top: -1rem;
    }
  }

  h5 {
    color: $gray;
    margin: 0;
  }

  .Minigraph {
    align-self: center;
    margin-bottom: -3.25rem;
  }

  .summary-bottom {
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 0);

    .link {
      margin-right: -0.75rem;
      width: 12rem;
    }

    & > * {
      align-self: center;
    }

    img {
      margin-bottom: 0.25rem;
      margin-left: 1rem;
      margin-right: 0.25rem;
    }

    h5 {
      width: 15rem;
    }
  }

  .summary-bottom-left {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    & > * {
      align-self: center;
    }
  }
}

.FAQ {
  display: flex;
  flex-direction: column;
  min-height: 30rem;
  width: 100%;

  & > * {
    align-self: center;
    margin-bottom: 1.5rem;
    width: 30rem;
  }

  & > *:first-child {
    margin-top: 2rem;
  }

  h2 {
    font-weight: 600;
    margin: 0;
    text-transform: none;
  }

  .question {
    color: $gray-dark;
    margin: 0;
    margin-bottom: 0.25rem;
  }

  .answer {
    color: $blue;
    line-height: 1.5rem;
  }

  a {
    background: $blue-light;
    color: $blue;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.PatientsDB {
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  .select {
    display: flex;
    flex-direction: row;
    text-align: center;

    .react-date-picker {
      align-items: center;
      background-color: #eee;
      border: 2px solid #e8e8e9 !important;
      border-radius: 4px;
      color: #6c757d;
      cursor: default;
      font-family: 'Shan';
      font-size: 12px;
      font-weight: 900;
      height: 2.19rem;
      margin: 0.2rem 0.1rem;
      padding: 0.5rem;
      padding-inline-start: 1px;
      width: auto;

      input,
      button {
        &:focus {
          outline: none;
        }
      }
    }

    .react-date-picker,
    .react-date-picker *,
    .react-date-picker *::before,
    .react-date-picker *::after {
      border: 0;
    }

    .react-calendar {
      background: $gray-light-opaque;
      border: 2px solid $gray-light;
      border-radius: 5px;

      button {
        border-radius: 5px;
        font-family: 'Shan';
        padding: 1rem;
        transition: all 0.1s ease-in-out;

        &:disabled {
          border-radius: 0 !important;
          color: $gray-mid;
          cursor: not-allowed;
        }
      }
    }

    .react-date-picker__inputGroup__input {
      &:invalid {
        background: transparent;
      }
    }

    .react-date-picker__wrapper {
      border: unset;

      svg {
        stroke: $gray-mid;

        &:hover {
          stroke: $gray;
        }
      }
    }

    .react-calendar__tile--active {
      background: $pblue-light;
      color: $pblue !important;

      &:hover {
        background: $pblue-hover;
        color: $pblue !important;
      }
    }

    .react-calendar__month-view__weekdays__weekday {
      font-family: 'Shan';
      margin-bottom: 0.5rem;
    }

    .react-calendar__month-view__days__day--weekend,
    .react-calendar__month-view__days__day {
      color: $gray;
    }

    .react-date-picker__calendar {
      top: 3.5rem !important;
    }

    .react-calendar__navigation__label {
      color: $gray !important;
    }

    .react-calendar__navigation__arrow {
      color: $gray;
    }

    .react-date-picker__inputGroup > * {
      color: $gray !important;
    }

    .react-date-picker__clear-button {
      margin: 0 !important;
      padding: 0 !important;

      svg {
        stroke-width: 3px;
        width: 16px;
      }
    }

    .react-date-picker__calendar-button {
      margin: 0 !important;
      margin-left: 0.25rem !important;
      padding: 0 !important;

      svg {
        height: 16px;
        stroke-width: 3px;
        width: 16px;
      }
    }

    select {
      appearance: none;
      background-color: #eee;

      background-image: linear-gradient(45deg, transparent 50%, #808080 50%),
        linear-gradient(135deg, #808080 50%, transparent 50%);
      background-position: calc(100% - 13px) 50%, calc(100% - 8px) 50%;
      background-repeat: no-repeat;
      background-size: 5px 5px, 5px 5px;
      border: 2px solid #e8e8e9;
      border-radius: 4px;
      color: $gray;
      cursor: pointer;
      font-family: 'Shan';
      font-weight: 900;
      margin: 0.2rem 0.1rem;
      padding: 0.5rem;
      width: 130px;

      &:focus {
        outline: none;
      }
    }
  }

  .reminder {
    align-self: center !important;
    background: $pblue-light;
    border-radius: 5px;
    color: $pblue;
    font-family: 'Shan';
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 23px;
    margin-bottom: 3rem;
    margin-top: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    text-align: center;
    width: 30rem;

    svg {
      cursor: pointer;
      padding: 0.5rem;
      position: absolute;
      right: 0;
      stroke: $pblue-mid;
      stroke-width: 3px;
      top: -0.25rem;
      width: 16px;

      &:hover {
        stroke: $pblue;
      }
    }
  }

  .header {
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.75rem;
    width: calc(100% - 10rem);

    h1 {
      color: $pblue;
    }

    h3 {
      color: $pblue-mid;
      font-weight: 900;
      margin-top: 0;
    }

    h6 {
      &.disclaimer {
        color: $gray-mid;
        margin-bottom: 1rem;
        text-align: right;
        width: 10rem;
      }
    }

    .deep-dive {
      align-self: flex-start;
      display: flex;
      flex-direction: row;

      h5 {
        color: $gray;
        margin: 0;
        margin-right: 1rem;
      }
    }
  }

  .patientdb-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 5rem;
    padding-right: 5rem;

    .no-result {
      align-self: center;
      display: flex;
      flex-direction: column;
      height: 20rem;
      justify-content: center;

      h5 {
        color: $gray;
        font-weight: 600;
        text-align: center;

        span {
          color: $pblue !important;
          font-weight: 900;
        }
      }
    }
  }

  .legend {
    z-index: unset;
  }
}

.Patients {
  align-self: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  & > * {
    align-self: center;
  }

  h5 {
    &.daylabel {
      color: $pblue;
    }
  }

  .day {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .patient-card {
    background: $gray-light;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 3.25rem;
    justify-content: center;
    margin: 0.25rem;
    transition: background 0.3s ease-in-out;
    width: 3.25rem;

    & > * {
      align-self: center;
    }

    &:hover {
      background: $gray-hover;
    }

    h3 {
      color: $gray-mid;
      font-size: 11px !important;
      font-weight: 900;
    }

    &.is-small {
      background: $gray-hover;
      height: 1rem;
      width: 1rem;

      &:hover {
        background: $gray-mid;
      }

      &.is-femme {
        background: $pink;
      }

      &.is-male {
        background: $pblue;
      }

      &.is-local {
        background: $cherry;
      }

      &.is-imported {
        background: $purple;
      }
    }

    &.is-femme {
      background: $pink-mid;

      h3 {
        color: $pink;
      }

      &:hover {
        background: $pink-hover;
      }
    }

    &.is-male {
      background: $pblue-light;

      h3 {
        color: $pblue;
      }

      &:hover {
        background: $pblue-hover;
      }
    }

    &.is-local {
      background: $cherry-light;

      h3 {
        color: $cherry;
      }

      &:hover {
        background: $cherry-hover;
      }
    }

    &.is-imported {
      background: $purple-light;

      h3 {
        color: $purple;
      }

      &:hover {
        background: $purple-hover;
      }
    }

    &.is-us {
      background: url('/flags/usa.png');
      background-size: cover !important;

      h3 {
        color: transparent;
      }

      &:hover {
        background: $gray-light;

        h3 {
          color: $gray-mid;
        }
      }
    }

    &.is-ch {
      background: url('/flags/switzerland.png');
      background-size: cover !important;

      h3 {
        color: transparent;
      }

      &:hover {
        background: $gray-light;

        h3 {
          color: $gray-mid;
        }
      }
    }

    &.is-fr {
      background: url('/flags/french.png');
      background-size: cover !important;

      h3 {
        color: transparent;
      }

      &:hover {
        background: $gray-light;

        h3 {
          color: $gray-mid;
        }
      }
    }

    &.is-mm {
      background: url('/flags/myanmar.png');
      background-size: cover !important;

      h3 {
        color: transparent;
      }

      &:hover {
        background: $gray-light;

        h3 {
          color: $gray-mid;
        }
      }
    }

    &.age1-9 {
      background: #006400;
    }

    &.age10-19 {
      background: #00008b;
    }

    &.age20-29 {
      background: #b03060;
    }

    &.age30-39 {
      background: #ff4500;
    }

    &.age40-49 {
      background: #ff0;
    }

    &.age50-59 {
      background: #deb887;
    }

    &.age60-69 {
      background: #0f0;
    }

    &.age70-79 {
      background: #0ff;
    }

    &.age80-89 {
      background: #f0f;
    }

    &.age90-99 {
      background: #6495ed;
    }
  }
}

.DownloadBlock {
  align-self: center;
  margin-top: 7rem;

  code {
    color: $orange-mid;
    font-family: 'Shan';
  }

  a {
    color: $orange-mid;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-decoration: none !important;
  }

  .button {
    background: $orange-light;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;

    & > * {
      align-self: center !important;
    }

    &:hover {
      background: $orange-hover;
    }
  }

  svg {
    stroke: $orange-mid;
    stroke-width: 3;
  }
}

.modal {
  background: $pblue-mid;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;

  .modal-content {
    align-self: center;
    background: #fff;
    border-radius: 5px;
    cursor: default;
    height: 30rem;
    overflow-y: auto;
    padding: 1rem;
    position: fixed;
    width: 30rem;

    svg {
      stroke: $pblue-mid;
      stroke-width: 3;
      transition: all 0.1s ease-in-out;
      width: 20px;

      &:hover {
        stroke: $pblue;
      }
    }

    .close-button {
      cursor: pointer;
      position: absolute;
      right: 1rem;
    }

    h1 {
      color: $pblue;
    }

    h5,
    h3 {
      font-weight: 900;
      margin: 0;
    }

    h3 {
      color: $pblue;
      margin-bottom: 0.5rem;
    }

    h5 {
      color: $pblue-mid;
    }

    .meta {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 11rem;
      & > * {
        width: 10rem;
      }

      h3 {
        &.contracted-from {
          cursor: pointer;
          text-decoration: underline;
          text-decoration-style: dotted;
          transition: all 0.1s ease-in-out;
          width: 2rem;

          &:hover {
            background: $pblue-light;
          }
        }
      }
    }

    .modal-top {
      display: flex;
      flex-direction: row;

      & > {
        align-self: center;
      }
    }

    .notes {
      margin-bottom: 2rem;
      margin-top: 2rem;
      width: 10rem;
    }

    .link {
      margin: 0;
      margin-bottom: 0.5rem;
      margin-top: 0.25rem;

      a {
        background: $pblue-light;
        color: $pblue;
        transition: background 0.2s ease-in-out;

        &:hover {
          background: $pblue-hover;
        }
      }
    }
  }
}

.patients-summary {
  align-self: center;
  width: 30rem;

  .Patients {
    min-height: 5rem;
  }

  h1 {
    color: $gray-dark;
    margin-bottom: 0.25rem;
  }

  h6 {
    color: $gray;
    font-weight: 600;
    margin: 0;
    margin-top: 0;
  }

  .summary {
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
  }

  .patients-summary-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;

    & > * {
      align-self: center;
    }
  }

  .button {
    background: $pblue-light;
    color: $pblue;
    display: flex;
    flex-direction: row;
    height: 3rem;
    margin-top: 1rem;

    & > * {
      align-self: center;
    }

    &:hover {
      background: $pblue-hover;
    }
  }

  a {
    color: inherit;
    display: flex;
    flex-direction: row;
    text-decoration: none;

    & > * {
      align-self: center;
    }
  }

  .daylabel {
    align-self: flex-start;
  }

  .link {
    a {
      display: inline !important;
      padding-bottom: 0;
    }
  }

  .legend {
    justify-content: flex-start;
    padding: 1rem;
    padding-bottom: 0.25rem;
    padding-left: 0;
  }
}

.select {
  display: flex;
  flex-direction: column;

  label {
    color: $gray-mid;
    font-size: 0.75rem;
    font-weight: 900;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
  }
}

.filters {
  background: #fff !important;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12), inset 0 -1px 0 0 #dadce0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: Shan;
  justify-content: space-between;
  padding: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 101;

  & > * {
    align-self: center;
  }

  .filters-left {
    display: flex;
    flex-direction: row;
  }
}

.pills {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  > button {
    background-color: $yellow-light;
    border: 2px $yellow-light solid;
    border-bottom: 0;
    border-top: 0;
    color: $orange;
    cursor: pointer;
    font-family: 'Shan';
    font-weight: 900;
    margin: 0;
    outline: none;
    padding: 10px 15px;
    text-align: center;
    transition: background 0.25s ease-in-out;

    &:hover {
      background: $yellow-hover;
    }

    &.selected {
      background-color: $yellow-mid;
    }

    &:focus {
      &:not(:focus-visible) {
        outline: none;
      }
    }

    &:first-of-type {
      border: 0;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }

    &:last-of-type {
      border: 0;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}

.legend {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  font-family: Shan;
  justify-content: center;
  z-index: 101;

  & > * {
    align-self: center;
  }

  .legend-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  select {
    margin-left: 1rem;
  }

  h5 {
    color: $gray;
    margin: 0;
    margin-right: 0.5rem;

    &.is-female {
      color: $pink;
    }

    &.is-male {
      color: $pblue;
    }

    &.is-local {
      color: $cherry;
    }

    &.is-imported {
      color: $purple;
    }
  }

  .circle {
    background: $gray-mid;
    border-radius: 50%;
    height: 0.8rem;
    margin-right: 0.25rem;
    width: 0.8rem;

    &.is-female {
      background: $pink;
    }

    &.is-male {
      background: $pblue;
    }

    &.is-local {
      background: $cherry;
    }

    &.is-imported {
      background: $purple;
    }

    &.is-fr {
      background: url('/flags/french.png');
      background-size: cover !important;
    }

    &.is-ch {
      background: url('/flags/switzerland.png');
      background-size: cover !important;
    }

    &.is-us {
      background: url('/flags/usa.png');
      background-size: cover !important;
    }

    &.is-mm {
      background: url('/flags/myanmar.png');
      background-size: cover !important;
    }
  }
}

.updates-header {
  align-self: center;
  color: $gray-dark;
  width: 30rem;

  h2 {
    color: $pblue;
    font-weight: 900;
  }
}

.updates {
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  min-height: 22rem !important;
  width: 100%;

  .update {
    background: $gray-light;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    padding: 0.75rem;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: $gray-hover;
    }

    h5,
    h4 {
      margin: 0;
    }

    h5 {
      color: $gray-mid;
      margin-bottom: 0.15rem;
    }

    h4 {
      color: $gray;
      font-weight: 600;
    }
  }

  .button {
    background: $pblue-light;
    color: $pblue;
    display: flex;
    flex-direction: row;
    height: 3rem;
    width: 13rem;

    & > * {
      align-self: center;
    }

    &:hover {
      background: $pblue-hover;
    }
  }

  a {
    color: inherit;
    display: flex;
    flex-direction: row;
    text-decoration: none;

    & > * {
      align-self: center;
    }
  }
}

.DeltaBarGraph {
  svg {
    width: 100%;

    text {
      color: $red;
      font-family: 'Shan';
    }

    .delta {
      fill: #dc3545;
      font-weight: 900;
    }

    .percent {
      fill: #dc354590;
    }
  }
}

.district-bar {
  align-self: center;
  flex-direction: row;
  justify-content: space-between;

  h2 {
    color: $red;
    font-weight: 900;
    margin-bottom: 0.5rem;
  }

  .district-bar-left {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;

    .button {
      background: $gray-light;
      color: $gray;
      font-weight: 600;
      height: 2.5rem;
      justify-content: center;
      margin-top: 0.75rem;
      text-transform: unset;
      width: 5rem;

      &:hover {
        background: $gray-hover;
      }
    }
  }

  .districts {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;

    &.is-grid {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .district {
      display: flex;
      flex-direction: row;
      font-family: 'Shan';
      height: 2.5rem;
      justify-content: flex-start;
      margin-right: 1rem;

      h2,
      h5 {
        color: $gray;
        font-weight: 600;
        margin: 0;
      }

      h5 {
        display: inline-block !important;
        flex: 0 1;
        margin-left: 0.5rem;
        margin-top: 0.15rem;
        width: auto !important;
      }

      & > * {
        align-self: center;
      }

      .delta {
        display: flex;
        flex-direction: row;
        margin-left: 0.5rem;
        margin-top: 4px;

        h6 {
          color: $red-mid;
          margin: 0;
        }

        svg {
          color: $red-mid;
          stroke-width: 4;
          vertical-align: bottom;
          width: 10px;
        }

        & > * {
          align-self: center;
        }
      }
    }
  }
}

.Clusters {
  align-self: center;
  width: 100%;

  h1 {
    color: $red;
    margin-bottom: 0;
    padding-left: 1.5rem;
    width: 100%;
  }

  canvas {
    touch-action: auto !important;
  }
}

.cards-container {
  margin-left: 5rem;
  padding: 2rem;

  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .card {
      flex: 0 1 calc(33% - 1em);
      height: 30rem;
      margin-bottom: 2em;

      .charts-header {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;

        .chart-title {
          color: #6c757d;
          font-family: 'Shan';
          font-size: 1.5rem !important;
          font-weight: 900;
          padding: 20px 0;
          text-transform: uppercase;
        }

        .chart-content {
          height: 100%;
          width: 100%;
        }

        .chart-note {
          color: $gray;
          font-family: 'Shan';
          font-size: 0.8rem;
          height: 50px;
          margin-top: 5px;
        }
      }
    }
  }

  @media (max-width: 769px) {
    margin-left: unset;
    padding: 1rem;
  }
}

@media (min-width: 769px) {
  .Home,
  .State {
    padding-top: 5rem;
  }

  .Navbar {
    background: #fff;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    min-width: 5rem;
    position: fixed;
    z-index: 99999;
    .navbar-left {
      order: 4;
      margin-top: auto;
      order: 3;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
      transition: all 0.2s ease-in-out;
      width: 5rem;

      &:hover {
        background: $gray-hover;
      }
    }

    .navbar-middle {
      font-size: 0.6rem;
      order: 1;
      padding-bottom: 2rem;
      padding-top: 2rem;
      text-align: center;
      width: 3.2rem;
      word-wrap: break-word;
    }

    .navbar-right {
      background: #fff;
      display: flex;
      flex-direction: column;
      order: 2;
      padding-bottom: 0;
      padding-top: 0;

      &:hover {
        background: $gray-light-opaque !important;
      }

      & > span {
        display: flex;
        flex-direction: row;
        height: 1.5rem;
        justify-content: center;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;

        svg {
          stroke: $gray;
        }
      }
    }

    .expand {
      background: #fff;
      border-right: solid 2px $gray-light-opaque;
      flex-direction: column;
      padding-left: 0;
      padding-top: 5.5rem;
      top: 0;
      width: 15rem;
      z-index: -1 !important;

      & > * {
        border: 0;
        border-left: 1px solid $gray-light;
        color: $gray;
        font-weight: 600;
        height: 1.5rem;
        width: 12rem;
      }
    }
  }

  .PatientsDB {
    margin-left: 5rem;
  }

  .dark-mode {
    .Navbar,
    .expand {
      background: #1e1e30 !important;
    }

    .navbar-right {
      background: #1e1e30 !important;
    }
  }
}

@media (max-width: 769px) {
  table,
  .header,
  .Level,
  .TimeSeriesExplorer,
  .Links,
  .Minigraph,
  .Summary,
  .FAQ,
  .MapExplorer,
  .filters,
  .updates,
  .map-switcher,
  .updates-header {
    width: calc(100% - 2rem);
  }

  .Home,
  .State {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    width: 100%;

    .home-left,
    .home-right,
    .state-left,
    .state-right {
      display: flex;
      flex-direction: column;
      margin: 0;
      width: 100%;
    }
  }

  .MapExplorer {
    .svg-parent {
      width: 100%;
    }

    .header {
      margin-top: 2rem;
    }
  }

  .link,
  .faq {
    width: calc(100% - 2rem);
  }

  .TimeSeries {
    .svg-parent {
      width: 100%;
    }
  }

  .Minigraph {
    .svg-parent {
      padding: 0;
    }
  }

  .map-stats {
    .stats {
      .timestamp {
        width: 4.5rem;
      }
    }
  }

  .last-update {
    width: 10rem;
  }

  a {
    &.button {
      display: flex;
      flex: row;
      justify-content: center;
      width: 10rem;

      & > * {
        align-self: center;
      }

      svg {
        width: 38px;
      }
    }
  }

  .Banner {
    height: 7rem;

    .snippet {
      margin-left: 1rem;
      margin-right: 1rem;
      width: 100%;
    }
  }

  table {
    td {
      max-width: 6.75rem;
    }
  }

  .cards-container {
    padding: 0.5rem;

    .cards {
      .card {
        flex: 0 1 100%;
        height: 25rem;
        margin-bottom: 0;
      }

      .chart-title {
        padding: 20px 0 10px;
      }

      .card-big {
        height: 30rem;
      }
    }
  }

  .PatientsDB {
    width: 100%;

    .select {
      .react-date-picker__calendar {
        position: fixed;
        top: 2.3rem !important;
      }
    }

    .header {
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;
      width: calc(100% - 2rem);
    }

    .reminder {
      padding-bottom: 1rem;
      padding-top: 1rem;
      width: calc(100% - 4rem);
    }

    .Patients {
      width: calc(100% - 2rem);
    }

    .modal {
      .modal-content {
        width: calc(100% - 5rem);

        .meta {
          height: 20rem;
        }
      }
    }

    .filters-left {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .filters {
      height: auto;
      justify-content: center;
      padding: 0.5rem;
    }
  }

  .table-fineprint {
    margin-right: 1.25rem;
  }

  .patients-summary {
    width: calc(100% - 2rem);

    .modal {
      .modal-content {
        width: calc(100% - 5rem);

        .meta {
          height: 20rem;
        }
      }
    }
  }

  .patientdb-wrapper {
    padding-left: 1rem !important;
    padding-right: 1rem !important;

    .Patients {
      width: 100%;
    }

    .day {
      justify-content: center;
      width: 100%;
    }
  }

  &.nationality {
    margin-bottom: 0.5rem;
  }

  .Resources {
    margin: 1rem;

    table {
      align-self: center;
      border-collapse: separate;
      border-spacing: 3px 2px;
      font-family: 'Shan';
      position: relative;
      text-transform: none;
      width: 40rem;

      thead {
        background: $purple-light;
        color: $gray-dark;
        font-size: 0.7rem;
        text-align: center;

        th {
          border-radius: 5px;
          cursor: pointer;
          padding: 0.5rem;
          transition: all 0.1s ease-in-out;
          z-index: 99;

          &.sticky {
            background: $gray-opaque;
            top: 4px;
          }

          &:hover {
            background: #ecedee;
          }

          .heading-content {
            align-items: center;
            display: flex;
            flex-direction: row;
            height: 0.9rem;
            justify-content: space-between;
            position: relative;

            abbr {
              text-align: right;
            }

            svg {
              color: $gray-mid;
              margin: 0;
              margin-left: 0.25rem;
              margin-top: 0.15rem;
              right: 0;
              stroke-width: 4px;
              width: 10px;
            }
          }
        }
      }

      tbody {
        color: $gray;
        max-width: 10rem;

        tr {
          transition: background 0.1s ease-in-out;

          &:hover {
            background: $gray-hover !important;
          }

          &.divider {
            background: #6c757d10 !important;
            height: 0.5rem;
          }

          &.spacer {
            background: #fff !important;
            height: 0.5rem;
          }

          &:nth-child(even) {
            background-color: $purple-light;
          }
        }

        td {
          border-radius: 5px;
          font-size: 0.6rem;
          text-align: left;

          &:first-child {
            max-width: 7.5rem;
            text-align: left;
            word-wrap: break-word;
          }

          .deltas {
            font-size: 11px !important;
            margin-right: 0.25rem;

            svg {
              height: 9px;
              stroke-width: 3;
              vertical-align: -0.25px;
              width: 9px;
            }
          }
        }

        .state-last-update {
          background: transparent;
          transform: translateX(1rem);

          &:hover {
            background: #fff !important;
          }

          td {
            .last-update {
              align-items: baseline;
              display: flex;
              flex-direction: row;
              text-align: left;
              width: 100%;
            }
          }
        }

        .district-heading {
          background: $gray-light;
          transform: translateX(1rem);

          td {
            background: $gray-light;
            color: $gray-dark;
            font-size: 0.75rem;
            font-weight: 900;
            padding: 0.45rem;

            .heading-content {
              align-items: center;
              display: flex;
              flex-direction: row;
              height: 0.9rem;
              justify-content: space-between;
              position: relative;

              abbr {
                text-align: right;
              }

              svg {
                color: $gray-mid;
                margin: 0;
                margin-left: 0.25rem;
                margin-top: 0.15rem;
                right: 0;
                stroke-width: 4px;
                width: 10px;
              }
            }
          }
        }

        .district {
          transform: translateX(1rem);
        }
      }

      .affected-count {
        color: $gray-mid;
        margin: 0;
        position: absolute;
        right: 0;
        top: -1rem;
      }

      .is-total {
        td {
          background: $gray-hover;
        }
      }

      .dropdown {
        background: $gray-light;
        border-radius: 50%;
        cursor: pointer;
        height: 13px;
        left: -0.75rem;
        margin-top: 0.5rem;
        position: absolute;
        transition: background 0.2s ease-in-out;
        width: 13px;

        &:hover {
          background: $gray-hover;
        }

        svg {
          left: 1px;
          position: absolute;
          stroke-width: 3;
          top: -0.35rem;
          width: 11px;
        }
      }
    }
  }

  .resourcefilters {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .resourcefilter {
    margin-bottom: 0.4rem;
  }

  .filtersection {
    animation-duration: 1s;
    animation-name: fadeInUp;
    animation-timing-function: ease-in;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.1rem;
    width: 100%;

    .filtertitle {
      align-self: center;
      color: $cherry;
      text-align: center;
    }
  }

  .pagination {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .select {
      margin-right: 0;
      margin-top: 0.4rem;
    }

    input {
      margin: 0.1rem;
    }

    h5 {
      margin-bottom: 0.1rem;
      margin-top: 0;
    }
  }

  .Navbar {
    .expand {
      height: calc(100vh - 3.9rem);
      overflow: auto;
      transform: none !important;
    }

    .navbar-right {
      & > span {
        display: none;

        &:first-child {
          display: initial;
        }
      }
    }
  }

  .Search {
    .result-name {
      max-width: 10rem !important;
    }
  }

  .State {
    .district-bar,
    .to-essentials,
    .Clusters {
      width: calc(100% - 3rem);

      h1 {
        padding-left: 0;
      }
    }
  }
}

// Dark Mode

.dark-mode {
  background: #161625 !important;
  color: #bdbdbd;
  transition: background-color 0.2s ease-out !important;

  .switch-wrapper > div {
    background: #161625 !important;
  }

  .expand {
    background: #1e1e30 !important;
  }

  .Navbar,
  .expand {
    border-right: 0;

    .navbar-middle {
      color: #bdbdbd;
    }

    .navbar-right {
      background: #1e1e30 !important;
    }
  }

  table {
    th {
      background: #1e1e30 !important;
    }

    .is-odd {
      background: #1e1e30 !important;
    }

    .state-page-link {
      background: #161625 !important;
    }

    .heading-content {
      color: #bdbdbd !important;
    }

    .spacer {
      background: #161625 !important;
    }
  }

  .Search {
    input {
      background: #1e1e30 !important;
    }
  }

  .State {
    .header-right {
      h5 {
        color: #9673b9bb !important;
      }

      h2 {
        color: #9673b9 !important;
      }

      a {
        background: #40008050 !important;
      }
    }

    .breadcrumb {
      ul {
        background: #1e1e30 !important;

        &::after {
          border-bottom-color: #1e1e30 !important;
        }

        li {
          a {
            color: $gray !important;

            &:hover {
              color: $white !important;
            }
          }
        }
      }
    }
  }

  .MapExplorer {
    #chart,
    #legend {
      filter: invert(1) hue-rotate(180deg) saturate(1.5) url('#balance-color');
    }

    .legend {
      background: #161625 !important;
    }

    .stats {
      &.is-purple {
        background: #40008050 !important;

        h1 {
          color: #9673b9 !important;
        }

        h5,
        h6 {
          color: #9673b9bb !important;
        }

        svg {
          stroke: #9673b9bb !important;

          &:hover {
            stroke: #9673b9 !important;
          }
        }
      }
    }

    .disclaimer {
      color: $gray-light-opaque;
    }
  }

  .TimeSeries,
  .Minigraph {
    .stats {
      &.is-purple {
        h5 {
          color: #9673b9bb !important;

          &.title {
            color: #9673b9 !important;
          }
        }

        h2,
        h6 {
          color: #9673b9 !important;
        }
      }
    }

    .svg-parent {
      &.is-purple {
        background: #40008050 !important;

        svg {
          .dot,
          .focus,
          .domain,
          .tick,
          line {
            stroke: #9673b9 !important;
          }

          .dot,
          .focus {
            fill: #9673b9 !important;
          }

          .trend {
            stroke: #9673b999 !important;
          }

          text {
            color: #9673b9bb !important;
            stroke: transparent;
          }
        }
      }
    }
  }

  .tab {
    &.focused {
      background: #1e1e30 !important;
      color: #bdbdbd !important;
    }
  }

  .pills {
    button {
      &.selected {
        background: #{$yellow}50;
      }
    }
  }

  .trends-state-name,
  .select {
    select {
      background: #1e1e30;
      background-image: linear-gradient(45deg, transparent 50%, $gray 50%),
        linear-gradient(135deg, $gray 50%, transparent 50%);
      background-position: calc(100% - 13px) 50%, calc(100% - 8px) 50%;
      background-repeat: no-repeat;
      background-size: 5px 5px, 5px 5px;
      border-color: #9494941a;
    }
  }

  input {
    &.switch {
      background-color: #161625 !important;
      border: 2px solid $gray-mid !important;

      &:checked {
        background-color: #6c757d99 !important;

        &::after {
          background-color: #cfcfcf !important;
        }
      }

      &::after {
        background-color: #cfcfcf !important;
      }
    }
  }

  .disabled {
    input {
      &.switch {
        background: $dark !important;
        border: $gray-light 2px solid !important;
        opacity: 0.7;

        &::after {
          background: $gray !important;
        }
      }
    }
  }

  .button {
    &.github {
      color: #bdbdbd !important;

      svg {
        stroke: #bdbdbd !important;
      }
    }
  }

  .FAQ {
    .question {
      color: $gray !important;
    }
  }

  .Resources {
    .filtersection {
      .disclaimercontainer {
        .mobile-disclaimer-button {
          background-color: $dark-m-white;
          color: #808080;
        }

        .disclaimer-button {
          background: none;
          color: $dark-mid-subtext;
        }

        .MuiSvgIcon-root {
          fill: $dark-m-white;
        }
      }

      .resourcefilters {
        .MuiFormLabel-root {
          color: rgba(232, 230, 227, 0.54);
        }

        .MuiInputBase-root {
          color: rgba(232, 230, 227, 0.87);
        }

        .MuiSelect-icon {
          color: $dark-m-white;
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: $dark-m-white;
        }

        .button {
          &.is-purple {
            background-color: rgba(31, 25, 158, 0.13);
            background-image: none;
            color: $dark-m-purple;
          }
        }

        div {
          .MuiFormControl-root {
            &:nth-child(1) {
              .MuiOutlinedInput-root {
                border-color: #dbd3d3;
                border-width: 25px !important;
                position: relative;
              }
            }
          }
        }

        .MuiSelect-select {
          &:not([multiple]) {
            option,
            optgroup {
              background-color: $dark-m-gray;
            }
          }
        }
      }

      .misclinkscontainer {
        .button {
          &.is-purple {
            background-color: rgba(31, 25, 158, 0.13);
            background-image: none;
            color: $dark-m-purple;
          }
        }
      }
    }

    input {
      .MuiInputBase-input {
        color: $dark-m-white;
      }
    }

    .searchbar {
      .MuiFormLabel-root {
        color: rgba(232, 230, 227, 0.54);
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: $dark-m-white;
      }

      .MuiSvgIcon-root {
        fill: $white;
      }
    }

    table {
      thead {
        color: rgb(210, 207, 200);
      }
    }

    .tableandcontrols {
      a {
        color: $dark-m-blue;
      }
    }
  }

  .PatientsDB {
    .filters {
      background: #1e1e30 !important;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12), inset 0 -1px 0 0 #1e1e30;
    }

    .legend {
      background: #1e1e30 !important;
    }

    .react-date-picker {
      background: #1e1e30;
      border-color: #9494941a !important;
    }

    .modal-content {
      background: #1e1e30 !important;
    }

    .modal {
      background: #1e1e30bb !important;
    }
  }

  .Clusters {
    canvas {
      filter: invert(1) hue-rotate(180deg) saturate(5);
    }
  }
}

// Animation Support

.fadeInUp {
  animation-duration: 0.45s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.rotateDownRight {
  animation-duration: 0.25s;
  animation-fill-mode: both;
  animation-name: rotateDownRight;
}

@keyframes rotateDownRight {
  100% {
    transform: rotate(-90deg);
  }
}

.rotateRightDown {
  animation-duration: 0.25s;
  animation-fill-mode: both;
  animation-name: rotateRightDown;
}

@keyframes rotateRightDown {
  0% {
    transform: rotate(-90deg);
  }
}

.fadeOutDown {
  animation-duration: 0.45s;
  animation-fill-mode: both;
  animation-name: fadeOutDown;
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}
